<template>
  <transition name="modal-fade">
    <div class="modal-backdrop"
    >
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
          <slot name="header">
            Entenda quais são os principais países de cada rota.
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            x
          </button>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            <div>
              <h1 class="title-body">• Oriente (Far East):</h1>
              <h3 class="subtitle-body">
                Extremo leste da Ásia: China, Coreia do Sul e Japão
              </h3>
              <h1 class="title-body">
                • Sudeste da Ásia (South East Asia)
              </h1>
              <h3 class="subtitle-body">
                Sul da China, Filipinas, Taiwan, Vietnã, entre outros
              </h3>
              <h1 class="title-body">
                • Mediterrâneo
              </h1>
              <h3 class="subtitle-body">
                Sul da Europa: Grécia, Itália, França, Espanha, entre outros.
              </h3>
              <h1 class="title-body">
                • Mar Negro
              </h1>
              <h3 class="subtitle-body">
                Leste da Europa: Ucrânia, Turquia, Romênia, entre outros.
              </h3>
              <h1 class="title-body">
                • Oriente Médio
              </h1>
              <h3 class="subtitle-body">
                Região das Arábias: Síria, Iraque, Arabia Saudita, entre outros
              </h3>
              <h1 class="title-body">
                • Índia Subcontinental
              </h1>
              <h3 class="subtitle-body">
                Índia, Nepal, Sri Lanka
              </h3>
              <h1 class="title-body">
                • Norte da Europa
              </h1>
              <h3 class="subtitle-body">
                Holanda, Bélgica, Alemanha, Inglaterra, entre outros
              </h3>
              <h1 class="title-body">
                • Países Bálticos (Baltc)
              </h1>
              <h3 class="subtitle-body">
                Estônia, Letônia, Lituânia
              </h3>
              <!-- <h1 class="title-body">
                • Costa Leste da América do Sul (ECSA)
              </h1>
              <h3 class="subtitle-body">
                Brasil, Argentina, Uruguai, entre outros.
              </h3> -->
            </div>
          </slot>
        </section>

        <footer class="modal-footer">
          <button
            type="button"
            class="btn"
            @click="close"
            aria-label="Close modal"
          >
            Fechar
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Modal',
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style>
  .modal-header{
    justify-content: space-evenly;
    display: flex;
    position: relative;
    border-bottom: 1px solid #eeeeee;
    background-color: #fff;
    color: #877953;
    font-size: 22px;
    font-weight: 700;
    position: fixed;
    z-index: 1000;
    padding-top: 1rem;
    left: 1rem;
    top: 0rem;
  }
  .subtitle-body{
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #666666 !important;
    margin-top: -20px;
    margin-left: 16px;
  }
  .title-body {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    line-height: 41px;
    color: #3d3d3d;
    padding-bottom: 15px;
  }
  .modal-backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 50rem;
    height: 45rem;
    top: 5rem;
    bottom: 16rem;
    left: 30%;
    z-index: 9999999;
  }

  .modal-header,
  .modal-footer {
    position: sticky;
    padding: 15px;
    display: flex;
  }

  /* .modal-header {
    justify-content: space-evenly;
    display: flex;
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #877953;
    font-size: 22px;
    font-weight: 700;
  } */

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 4px;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #877953;
    background: transparent;
  }

  .btn {
    color: #fff;
    background: #000000;
    border: 1px solid #877953;
    border-radius: 2px;
    right: 10px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .9s ease;
  }
</style>

import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const IndexService = {
  /**
   * http://localhost/api/v3/indice
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getMostRecent (idorigem, iddestino) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/indice/${idorigem}/${iddestino}?per_page=100`).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getMostRecentIndex () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/indice/12/8?per_page=100`).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getMostRecentIndex2 () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/indice/1/8?per_page=100`).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getMostRecentIndex3 () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/indice/10/8?per_page=100`).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getIndex () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/indice?per_page=100`).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default IndexService

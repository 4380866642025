<template>
    <div>
        <div
            class="title"
            @click="toggleAccordion()"
            :aria-expanded="isOpen"
            :aria-controls="`collapse${_uid}`"
        >
            <slot name="title" />
        </div >
        <div class="content" v-show="isOpen" :id="`collapse${_uid}`">
            <slot name="content" />
        </div>
    </div>
  </template>
<script>
export default {
  data () {
    return {
      isOpen: false
    }
  },

  methods: {
    toggleAccordion () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
<style>
.content{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 10px 5px;
    background: rgba(141, 171, 196, 0.09);
    color: rgb(51, 51, 51);
    line-height: 25px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
}
.title{
    cursor: pointer;
    display: block;
    font-size: 15px;
    padding: 10px 0px;
    border-top: 1px solid rgb(0, 0, 0);
}
</style>

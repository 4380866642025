<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartData: {
      type: Array,
      required: true
    },
    options: {
      type: Object
    },
    yGridLines: {
      type: Number,
      required: false,
      default: 4
    }
  },
  mounted () {
    this.chartGenerate()
  },
  methods: {
    stepSize (data, data2) {
      let dataWithoutNull = data.filter(Number)
      let data2WithoutNull = data2.filter(Number)

      let granuralidadeMinima = 100
      let maxValue = Math.max(...dataWithoutNull)
      let minValue = Math.min(...dataWithoutNull)

      let maxValueData2 = Math.max(...data2WithoutNull)
      let minValueData2 = Math.min(...data2WithoutNull)

      if (maxValueData2 > maxValue) {
        maxValue = maxValueData2
      }

      if (minValueData2 < minValue) {
        minValue = minValueData2
      }

      // Arredonda Max
      let resto = maxValue % granuralidadeMinima
      if (resto !== 0) {
        maxValue = maxValue + (granuralidadeMinima - resto)
      }

      // Arredonda Min
      resto = minValue % granuralidadeMinima
      if (resto !== 0) {
        minValue = minValue - resto
      }
      return (maxValue - minValue) / this.yGridLines
    },
    chartGenerate () {
      let data = []
      let dataLabel = []
      let data2Label2 = []
      let data2 = []
      let visits = 0
      let label = []
      let mes = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      for (let i = 0; i < this.chartData.length; i++) {
        let ano = this.chartData[i].ano.toString()
        let anoMais = (this.chartData[i].ano + 1).toString()
        ano = ano.slice(ano.length - 2)
        anoMais = anoMais.slice(anoMais.length - 2)
        label.push(`${mes[this.chartData[i].mes - 1]}-${ano}`)
        if (this.chartData[i].variacaoFrete === null) {
          visits = null
        } else {
          visits = ((this.chartData[i].variacaoFrete).toFixed(2))
        }
        data.push(visits)
        dataLabel.push(`${mes[this.chartData[i].mes - 1]}/${(this.chartData[i].ano.toString()).slice(-2)} USD`)
      }

      let options = Object.assign({}, this.options)

      let step = this.stepSize(data, data2)
      let formatedString = '<%= "R$ " + value.toString().split(".").join(",") %>'

      options.scales.yAxes[0].ticks.stepSize = step
      this.renderChart(
        {
          labels: label,
          datasets: [
            {
              label: dataLabel,
              data: data,
              borderColor: '#84754E',
              fill: false
            },
            {
              label: data2Label2,
              data: data2,
              borderColor: '#afafaf',
              fill: false
            }
          ],
          scales: {
            xAxes: [{
              offset: true
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                  return formatedString
                }
              }
            }]
          },
          options: {
            locale: 'de-DE'
          }
        }, this.options
      )
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler () {
        this.chartGenerate()
      }
    }
  }
}
</script>

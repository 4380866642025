<template>
  <div style="max-width: 99.9% !important;">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center" style="margin-top: -4.3rem">
      <div class="col-12 col-sm-12" style="max-width: 99.9% !important; z-index: 1055 !important">
        <div class="row">
          <div class="col-12">
            <header class="sc-gzVnrw otBsS" style="display: flex; flex-direction: column; align-items: flex-start;height: 120%;justify-content: space-between;">
              <div style="display:flex;width: 100%;padding-top: 2rem;" class="header-royal">
                <div class="header-rfx" style="display: flex; align-items: center; width: 46rem; justify-content: space-evenly;">
                  <a href="https://portal.royalcargo.com.br/dashboard" target="_blank"  rel="noopener noreferrer"> <img class="logoEroyal" src="./eroyal_fundo_escuro.png" style="width:4rem"/></a>
                  <h3 class="header-rfx-h3" style="font-weight: 1000; color: #fff; padding-top: 7px;">ROYAL </h3><h3 style="font-weight: 500; color: #fff; padding-top: 7px;">FREIGHT INDEX</h3>  <h3 style="font-weight: 1000; color: #fff; padding-top: 7px; font-size: 16px;">[RFX]</h3>
                </div>
                <div style="display: flex; justify-content: flex-end; align-items: center; width: 100%;">
                  <div class="links">
                    <a href="https://portal.royalcargo.com.br/"
                    style="visibility: hidden;color:#fff!important;margin-right: -4rem;"
                    title="Voltar para o portal."> Voltar</a>
                    <a href="https://royalcargo.com.br/quem-somos/"
                    style="color:#fff!important;margin-right: -4rem;"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Voltar para o portal."> Sobre a Royal™</a>
                    <div id="app">
                        <a style="color:#fff!important; cursor: pointer;" @click="showModal">
                          Ajuda
                        </a>
                        <Modal v-show="isModalVisible" @close="closeModal"/>
                      </div>
                  </div>
                </div>
              </div>
              <div style="display:flex;width: 100%;z-index: 9; background-color: #000;padding-top: 0.5rem; height: 3rem;">
                <div style="z-index: 9; margin-bottom: -5rem; background-color: #000;height: 93%;border-radius: 10rem;background-color: #00000000">
                  <div class="header1">
                    <h3 class="header-h3">{{dataHoje}}</h3>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <a href="https://portal.royalcargo.com.br/" class="header2" title="Voltar para o Portal">
                      <img src="./left.svg" class="icone-voltar" style="height: 25px">
                      <span class="header-h">Voltar</span>
                    </a>
                  </div>
                </div>
                <marquee-text style="padding-bottom: 0rem !important; background-color: #000;" :duration="110" :repeat="2">
                  <div style="display:flex; padding-top: 8px;">
                    <div style="display:flex" v-for="dado in tradelane1.data && tradelane2.data && tradelane3.data" :key="dado.id">
                      <div v-if="dado.variacaoValor">
                        <div style="padding: 0px 14px;width: 41rem;" @mouseenter="paused = !paused" @mouseleave="paused = false">
                          <span class="ml-12" style="align-items: center;font-size:1rem">{{ dado.origem ? dado.origem : '---' }} > {{ dado.destino ? dado.destino : '---' }} {{dado.mes > 0 ? monthNames[(dado.mes-1)] : '---'}}/{{dado.ano}}
                            <span v-if="dado.variacaoValor>0" class="badge badge-success ml-2" style="padding: 0.08rem 0.5rem !important">{{(dado.variacaoValor*100).toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1, decimalSeparator: "," })}}%</span>
                            <span v-if="dado.variacaoValor<0" class="badge badge-danger ml-2" style="padding: 0.08rem 0.5rem !important">{{(dado.variacaoValor*100).toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1, decimalSeparator: "," })}}%</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </marquee-text>
              </div>
            </header>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 chartMobile" style="">
        <div class="row rowmobile">
          <div class="col-4 col-3-mobile" style="max-width: 30% !important">
            <h3 class="letra"></h3>
            <div name="tradelane" id="tradelane">
              <a href="https://portal.royalcargo.com.br/" class="voltar" style="visibility: hidden;">
                <img src="./left.svg" class="icone-voltar" style="height: 25px">
                <span>voltar</span>
              </a>
              <div v-for="dado in AllTradelane.summary" :key="dado.id">
                <div>
                  <div :class="dado.codigo" class="card2 card card-topo card-topo2"  @click="iddestino = dado.iddestino_trade_lane; idorigem = dado.idorigem_trade_lane; alteraClasse(dado.codigo, oldAtr, dado.sparkline)">
                    <div class="card-body" style="display: flex; align-items: center; max-width: 100%;">
                      <div class="topo">
                        <div>
                          <div class="title1">
                            RFX {{ dado.codigo }}
                          </div>
                          <div class="lane">
                            <h1 class="lane" v-if="dado.codigo=='002'">
                              {{dado.origem_trade_lane}}
                            </h1>
                            <h1 class="lane" v-else>
                              {{dado.origem_trade_lane}}
                            </h1>
                          </div>
                          <div class="lane">
                            <h1 class="lane">
                              {{dado.destino_trade_lane}}
                            </h1>
                          </div>
                        </div>
                        <sparkline
                          style="height: 5rem;"
                          v-bind:data="dado.sparkline"
                        ></sparkline>
                        <div class="card22" style="padding-left: 1.1rem;    display: flex;flex-direction: column;align-items: flex-end;">
                          <div v-if="(dado.variacao_frete)" class="ganho">{{(parseFloat(dado.variacao_frete).toLocaleString('pt-BR', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})).replace('US$', 'USD')}}</div>
                          <div v-else class="ganho">USD 0</div>
                            <span style="display:flex; align-items: center;padding-top: 7px;">
                            <span v-if="dado.variacao_valor_m>=0" class="badge badge-success ml-2">{{(dado.variacao_valor_m*100).toFixed(1).replace('.', ',')}}%</span>
                            <span v-if="dado.variacao_valor_m<0" class="badge badge-danger ml-2">{{(dado.variacao_valor_m*100).toFixed(1).replace('.', ',')}}%</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8 col-9mobile" style="display: flex; justify-content: space-around;">
            <div style="max-width: 100%;">
              <div>
                <div class="titleRFX">
                  <div class="title-header">Acompanhe a flutuação de frete das principais Rotas Mundiais.</div>
                  <div class="sub-title"> A melhor forma para tomar suas decisões, de maneira estratégica, e com maior assertividade, é entendendo o mercado. <a target="_blank"  rel="noopener noreferrer" class="sub-title2" style="color:#84754E !important" href="https://portal.royalcargo.com.br/proposal/">Faça sua cotação agora mesmo!</a></div>
                </div>
                <div class="volat">
                  <div class="col-xs-6 col-sm-3" style="padding-left: 0px !important;">
                    <div class="box-rfx box-rfx-s" style="width: 365px;">
                        <div class="roow bottom-xs between-xs roow-bottom" style="flex-direction: row !important; align-content: center !important; align-items: center !important; justify-content: space-between !important; height: 100%;">
                          <div>
                            <div style="width: 100%">
                              <div class="text-rfx">
                                <div style="vertical-align: inherit;">
                                    <div style="vertical-align: inherit;" class="text-rfx">RFX {{ tradelane1.data[tradelane1.data.length - 1].codigo }}</div>
                                </div>
                              </div>
                                <span class="text-box">
                                  <div style="vertical-align: inherit;">
                                      <div v-if="(tradelane1.data[tradelane1.data.length - 1].variacaoFrete)" class="text-box" style="vertical-align: inherit; margin-top: 1vh;">{{((tradelane1.data[tradelane1.data.length - 1].variacaoFrete).toLocaleString('pt-BR', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})).replace('US$', 'USD')}}</div>
                                      <div v-else-if="(tradelane1.data[tradelane1.data.length - 2].variacaoFrete)" class="text-box" style="vertical-align: inherit; margin-top: 1vh;"> {{((tradelane1.data[tradelane1.data.length - 2].variacaoFrete).toLocaleString('pt-BR', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})).replace('US$', 'USD')}}</div>
                                      <div v-else-if="(tradelane1.data[tradelane1.data.length - 3].variacaoFrete)" class="text-box" style="vertical-align: inherit; margin-top: 1vh;"> {{((tradelane1.data[tradelane1.data.length - 3].variacaoFrete).toLocaleString('pt-BR', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})).replace('US$', 'USD')}}</div>
                                      <div v-else-if="(tradelane1.data[tradelane1.data.length - 4].variacaoFrete)" class="text-box" style="vertical-align: inherit; margin-top: 1vh;"> {{((tradelane1.data[tradelane1.data.length - 4].variacaoFrete).toLocaleString('pt-BR', {style: 'currency', currency: 'USD', minimumFractionDigits: 2})).replace('US$', 'USD')}}</div>
                                      <div v-else class="text-box" style="vertical-align: inherit; margin-top: 1vh;">USD 0</div>
                                  </div>
                                </span>
                            </div>
                            <div style="width: 100%; padding-top: 0.5rem;">
                              <span style="display: flex;">
                                <span style="width: 100%; display: flex; align-items: center; justify-content: space-between">
                                  <div v-if="(tradelane1.data[tradelane1.data.length - 1].variacaoValor) >= 0 || (tradelane1.data[tradelane1.data.length - 1].variacaoValor)" class="box-porc">
                                    <div v-if="(tradelane1.data[tradelane1.data.length - 1].variacaoValor)>-0.1" class="box-porc" style="background-color: #27AE60;">{{((tradelane1.data[tradelane1.data.length - 1].variacaoValor)*100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                                    <div v-else class="box-porc" style="background-color: #EB5757">{{((tradelane1.data[tradelane1.data.length - 1].variacaoValor)*100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                                  </div>
                                  <div v-else-if="(tradelane1.data[tradelane1.data.length - 2].variacaoValor)"  class="box-porc">
                                    <div v-if="(tradelane1.data[tradelane1.data.length - 2].variacaoValor)>=0" class="box-porc" style="background-color: #27AE60;">{{((tradelane1.data[tradelane1.data.length - 2].variacaoValor)*100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                                    <div v-else  class="box-porc" style="background-color: #EB5757">{{((tradelane1.data[tradelane1.data.length - 2].variacaoValor)*100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                                  </div>
                                  <div v-else-if="(tradelane1.data[tradelane1.data.length - 3].variacaoValor)"  class="box-porc">
                                    <div v-if="(tradelane1.data[tradelane1.data.length - 3].variacaoValor)>=0" class="box-porc" style="background-color: #27AE60;">{{((tradelane1.data[tradelane1.data.length - 3].variacaoValor)*100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                                    <div v-else  class="box-porc" style="background-color: #EB5757">{{((tradelane1.data[tradelane1.data.length - 3].variacaoValor)*100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                                  </div>
                                  <div v-else-if="(tradelane1.data[tradelane1.data.length - 4].variacaoValor)"  class="box-porc">
                                    <div v-if="(tradelane1.data[tradelane1.data.length - 4].variacaoValor)>=0" class="box-porc" style="background-color: #27AE60;">{{((tradelane1.data[tradelane1.data.length - 4].variacaoValor)*100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                                    <div v-else class="box-porc" style="background-color: #EB5757">{{((tradelane1.data[tradelane1.data.length - 4].variacaoValor)*100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                                  </div>
                                </span>
                              </span>
                            </div>
                          </div>
                          <div>
                            <sparkline
                                  style="height: 5rem;"
                                  v-bind:data="lastElementIndex"
                            ></sparkline>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="col-xs-6 col-sm-6 box-rfx-hdd" style="flex: 0 0 60% !important;max-width: 61%; min-width: 525px; height: 160px;">
                    <div class="box-rfx box-rfx-hd">
                      <div class="text-rfx">Grandes Movimentações</div>
                      <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                      <div style="height: 100%; display: flex; flex-direction: column; justify-content: space-around; margin-bottom: -20px;">
                          <div class="box-rfx2 sub-box"  style="color: #fff !important;margin-bottom: -8px;">
                            <div style="" v-if="tradelane1.grandesMovimentacoes.cima.tradelane.includes('COSTA LESTE DA AMÉRICA DO SUL', 'ECSA')">
                              <h1 class="text-rfx" style="width: 100%;">{{ tradelane1.grandesMovimentacoes.cima.tradelane.replace('COSTA LESTE DA AMÉRICA DO SUL', 'BRASIL') }}</h1>
                              <!-- <div v-if="tradelane1.grandesMovimentacoes.cima.tradelane.includes('COSTA LESTE DA AMÉRICA DO SUL', 'ECSA')">
                                ECSA: Costa Leste da América do Sul
                                Brasil, Argentina, Uruguai, entre outros.
                              </div> -->
                            </div>
                            <div v-else class="text-rfx">{{ tradelane1.grandesMovimentacoes.cima.tradelane.replace('COSTA LESTE DA AMÉRICA DO SUL', 'BRASIL') }}</div>
                            <span style="display: flex; width: 80px;justify-content: space-between;align-items: center;">
                              <span class="material-icons-outlined" style="color: #27AE60 !important; font-size: 33px;">
                                    arrow_drop_up
                                </span>
                              <div style="color: #27AE60;">{{(tradelane1.grandesMovimentacoes.cima.variacao_valor_m * 100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                            </span>
                          </div>
                          <div class="box-rfx2 sub-box">
                            <div class="text-rfx">{{ tradelane1.grandesMovimentacoes.baixo.tradelane.replace('COSTA LESTE DA AMÉRICA DO SUL', 'BRASIL') }}</div>
                            <span style="display: flex; width: 80px; justify-content: space-between;align-items: center;">
                                <span class="material-icons-outlined" style="color: #EB5757 !important; font-size: 33px;">
                                    arrow_drop_down
                                </span>
                                <div style="color: #EB5757;">{{(tradelane1.grandesMovimentacoes.baixo.variacao_valor_m * 100).toLocaleString('pt-BR', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}%</div>
                            </span>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
        <div class="col-4 col-3mobile" style="padding: 0;">
          <h3 class="letra"></h3>
          <div name="tradelane" id="tradelane">
            <div v-for="dado in AllTradelane.summary" :key="dado.id">
              <div>
                <div :class="dado.codigo" class="card2 card card-topo card-topo2"  @click="iddestino = dado.iddestino_trade_lane; idorigem = dado.idorigem_trade_lane; alteraClasse2(dado.codigo, oldAtr, dado.sparkline)">
                  <div class="card-body" style="display: flex; align-items: center; max-width: 100%;">
                    <div class="topo">
                      <div>
                        <div class="title1">RFX {{ dado.codigo }}</div>
                        <div class="lane">{{dado.origem_trade_lane}}</div>
                        <div class="lane">{{dado.destino_trade_lane}}</div>
                      </div>
                      <sparkline
                        class="sparkline-mobile"
                        style="height: 5rem;"
                        v-bind:data="dado.sparkline"
                      ></sparkline>
                      <div style="padding-left: 1.1rem;    display: flex;flex-direction: column;align-items: flex-end;">
                        <div v-if="(dado.variacao_frete)" class="ganho">USD {{parseInt(dado.variacao_frete,10).toLocaleString()}}</div>
                        <div v-else class="ganho">USD 0</div>
                          <span style="display:flex; align-items: center;padding-top: 7px;">
                          <span v-if="dado.variacao_valor_m>0" class="badge badge-success ml-2">{{(dado.variacao_valor_m*100).toFixed(1)}}%</span>
                          <span v-if="dado.variacao_valor_m<0" class="badge badge-danger ml-2">{{(dado.variacao_valor_m*100).toFixed(1)}}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
              <div style="padding-top: 3rem;">
                <h3 class="letra">{{tradelane1.data[0].origem ? tradelane1.data[0].origem : '---'}} <span style="font-size: 2rem;">→</span>  {{tradelane1.data[0].destino.replace('COSTA LESTE DA AMÉRICA DO SUL', 'Brasil') }}</h3>
                <div style="display:flex;">
                  <div v-on:click="(graf = 1, month1())">
                    <h3 class="month month1 month3" v-on:click="(graf = 1, month1())">MENSAL</h3>
                    <hr class="hr">
                  </div>
                  <div>
                    <h3 class="month month2" v-on:click="(graf = 2, month2())">ANO A ANO</h3>
                    <hr class="hr2">
                  </div>
                </div>
                <div class="card card2 card-graphic" style="width: 887px; max-width: 100%;">
                  <div class="card-body card-body-letreiro">
                    <div class="table-responsive " style="padding: 2rem 0 1rem 0">
                      <div v-if="graf===1" style="display:flex">
                        <div style="width: 92%">
                          <line-chart-global
                          ref='chart'
                          :options="chartOptions"
                          :height="500"
                          :chartData="this.value"
                          />
                        </div>
                        <div style="padding-left: 2rem;">
                          <div>
                            <h3 class="mont mont1 mont3" v-on:click="(value = tradelane1.data.slice(0, 12), mont1())">21/22</h3>
                            <hr class="h2r">
                          </div>
                          <div>
                            <h3 class="mont mont2" v-on:click="(value = tradelane1.data.slice(12, 25), mont2())">22/23</h3>
                            <hr class="h2r2">
                          </div>
                        </div>
                      </div>
                      <div v-if="graf===2" style="display:flex">
                        <div style="width: 92%">
                          <line-chart
                            :height="500"
                            :options="chartOptions2"
                            :chartData="tradelane1.data.slice(0, 12)"
                            :chartData2="tradelane1.data.slice(12, 25)"
                          />
                        </div>
                        <div style="padding-left: 2rem;">
                          <div>
                            <h3 class="subtitle">21/22</h3>
                            <hr class="h2r">
                          </div>
                          <div>
                            <h3 class="subtitle subtitle2">22/23</h3>
                            <hr class="h2r3">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-9mobile">
              </div>
            </div>
            <div v-on:change="changeColor()" class="switch">
              <input
                @change="trocaTema"
                id="checkbox"
                type="checkbox"
                class="switch-checkbox"
              />
              <label for="checkbox" class="switch-label">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M23.31 12L20 15.31V20H15.31L12 23.31L8.69 20H4V15.31L0.690002 12L4 8.69V4H8.69L12 0.690002L15.31 4H20V8.69L23.31 12ZM18 12C18 8.69 15.31 6 12 6V18C15.31 18 18 15.31 18 12Z" fill="black" fill-opacity="0.54"/>
                </svg>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 chartMobile" style="margin-top: -3px;">
        <div class="row rowmobile">
          <div class="col-4 col-3-mobile">
          </div>
          <div class="col-8 col-9mobile perguntas" style="margin-left: -5rem; display: flex; justify-content: space-around;">
            <div style="width: 887px;max-width: 100%;">
              <h3 class="primary" style="font-size:23px; margin-bottom:5px;color: #fff">PERGUNTAS FREQUENTES</h3>
              <app-accordion-index>
                <template v-slot:title class="click">
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                      <span class="font-semibold text-xl secondary secondary"  style="color: #fff;">O que é RFX?</span>
                      <div class="plus">
                        <span class="material-icons-outlined" style="color: #fff;">add</span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:content style="z-index: 99">
                    <p class="paragraph">
                      O RFX (Royal Freight IndeX) é um indicador que visa oferecer ao mercado um índice de flutuação dos preços de fretes internacionais separado por Trade Lanes.
                    </p>
                  </template>
              </app-accordion-index>
                <!-- </label> -->
              <hr class="hr-footer">
              <app-accordion-index>
                <template v-slot:title>
                  <div style="display: flex;align-items: center;justify-content: space-between;">
                    <span class="font-semibold text-xl secondary" style="color: #fff;">Como esse cálculo é feito?</span>
                    <div class="plus">
                      <span class="material-icons-outlined" style="color: #fff;">add</span>
                    </div>
                  </div>
                </template>
                <template v-slot:content>
                  <p class="paragraph">
                    O índice RFX mede a variação dos preços de frete internacional com base em uma amostra dados, considerando. mês anterior (M-1). Esta amostra compreende um conjunto de Origens e Destinos, Valor Unitário do Frete praticado por Armador para determinado período do ano.

                    Também é considerado no cálculo, a participação percentual de cada armador no mercado de frete internacional, com base em seus volumes.  Esse processo de pesos por participação de armadores no cenário internacional, consiste em um método de redução de viés de preço.

                    O algoritmo de cálculo considera, portanto, além da participação percentual dos armadores no mercado a distribuição dos preços praticados. Uma vez identificada a distribuição desses preços, a mediana da amostra é utilizada como base, considerando assim, o preço mais praticado por armador.
                  </p>
                </template>
              </app-accordion-index>
              <hr class="hr-footer">
              <app-accordion-index>
                <template v-slot:title>
                  <div style="display: flex;align-items: center;justify-content: space-between;">
                    <span class="font-semibold text-xl secondary" style="color: #fff;">Esses dados são confiáveis?</span>
                    <div class="plus">
                      <span class="material-icons-outlined" style="color: #fff;">add</span>
                    </div>
                  </div>
                </template>
                <template v-slot:content>
                  <p class="paragraph">
                    Sim, todos os dados passam por uma curadoria e são validados com base nos estudos de mercado, dando total credibilidade dos valores demonstrados.
                  </p>
                </template>
              </app-accordion-index>
              <hr class="hr-footer">
              <app-accordion-index>
                <template v-slot:title>
                  <div style="display: flex;align-items: center;justify-content: space-between;">
                    <span class="font-semibold text-xl secondary" style="color: #fff;">Com qual frequência esses dados são atualizados?</span>
                    <div class="plus">
                      <span class="material-icons-outlined" style="color: #fff;">add</span>
                    </div>
                  </div>
                </template>
                <template v-slot:content style="transition: 0.5s ease-in-out;">
                  <p class="paragraph">
                    Os dados são atualizados mensalmente, apresentando a análise histórica até o mês anterior.
                  </p>
                </template>
              </app-accordion-index>
              <hr class="hr-footer">
              <app-accordion-index>
                <template v-slot:title>
                  <div style="display: flex;align-items: center;justify-content: space-between;">
                    <span class="font-semibold text-xl secondary" style="color: #fff;">Quais informações eu tenho acesso?</span>
                    <div class="plus">
                      <span class="material-icons-outlined" style="color: #fff;">add</span>
                    </div>
                  </div>
                </template>
                <template v-slot:content style="transition: 0.5s ease-in-out;">
                  <p class="paragraph">
                    No RFX, você tem acesso a indicadores de cada Trade Lane, como: último valor do preço de frete em BRL, última variação de preço em %, visualização mensal, visualização comparativa anual e mensal, e as grandes movimentações como a maior alta e baixa de preço.
                  </p>
                </template>
              </app-accordion-index>
              <hr class="hr-footer">
              <app-accordion-index>
                <template v-slot:title>
                  <div style="display: flex;align-items: center;justify-content: space-between;">
                    <span class="font-semibold text-xl secondary" style="color: #fff;">Onde posso obter mais informações?</span>
                    <div class="plus">
                      <span class="material-icons-outlined" style="color: #fff;">add</span>
                    </div>
                  </div>
                </template>
                <template v-slot:content style="transition: 0.5s ease-in-out;">
                  <p class="paragraph">
                    Você pode obter mais informações entrando em contato com nosso time de especialistas através do e-mail <a class="mailto" href="mailto:eroyal@royalcargo.com.br">eroyal@royalcargo.com.br</a>
                  </p>
                </template>
              </app-accordion-index>
              <hr class="hr-footer">
              <app-accordion-index>
                <template v-slot:title>
                  <div style="display: flex;align-items: center;justify-content: space-between;">
                    <span class="font-semibold text-xl secondary" style="color: #fff;">O que são Grandes Movimentações?</span>
                    <div class="plus">
                      <span class="material-icons-outlined" style="color: #fff;">add</span>
                    </div>
                  </div>
                </template>
                <template v-slot:content style="transition: 0.5s ease-in-out;">
                  <p class="paragraph">
                    Você pode obter mais informações entrando em contato com nosso time de especialistas através do e-mail <a class="mailto" href="mailto:eroyal@royalcargo.com.br">eroyal@royalcargo.com.br</a>
                  </p>
                </template>
              </app-accordion-index>
            </div>
          </div>
          <h6 style="margin-top: 8rem">Copyright 2023 Royal Cargo do Brasil  |  Privacy Policy</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Modal from '@/components/Modal2.vue'
import VSwitch from 'v-switch-case'
import Sparkline from '@/components/Sparkline'
import IndexService from '@/services/IndexService'
import AppAccordionIndex from '@/components/AppAccordionIndex'
import LineChart from '@/components/ChartJs/LineChart.vue'
import LineChartGlobal from '@/components/ChartJs/LineChartGlobal.vue'

// Services
import ImportacaoDeParaService from '@/services/ImportacaoDeParaService'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'
import MarqueeText from 'vue-marquee-text-component'
import { string } from '@amcharts/amcharts4/core'

Vue.component('line-chart-global', {
  template: '#line-chart-global',
  props: ['chartData', 'chartData2']
})
Vue.use(VSwitch)
export default {
  name: 'MyPage',
  emits: ['trocarTradelane'],
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('index.de-para.title') + ' - %s'
    }
  },
  data () {
    return {
      userTheme: 'light-theme',
      onLoad: true,
      isModalVisible: false,
      chartOptions: {
        spanGaps: true,
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              if (data.datasets.length > 1) {
                let label = data.labels[tooltipItem[0].index]

                if (!label) {
                  return null
                }

                return null
              } else {
                let label = data.labels[tooltipItem[0].index]

                if (!label) {
                  return null
                }

                return null
              }
            },
            label: function (tooltipItem, data) {
              let formatNumber = function (number) {
                let decimals = 2
                let decPoint = ','
                let thousandsSep = '.'

                number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
                var n = !isFinite(+number) ? 0 : +number
                var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
                var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
                var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
                var s = ''

                var toFixedFix = function (n, prec) {
                  var k = Math.pow(10, prec)
                  return '' + (Math.round(n * k) / k)
                    .toFixed(prec)
                }

                // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
                s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
                if (s[0].length > 3) {
                  s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
                }
                if ((s[1] || '').length < prec) {
                  s[1] = s[1] || ''
                  s[1] += new Array(prec - s[1].length + 1).join('0')
                }
                // End Number format function
                return s.join(dec)
              }

              if (data.datasets.length > 1) {
                let message = ''

                message = data.datasets.map((e) => {
                  if (!e.data[tooltipItem.index]) {
                    return null
                  }
                  return e.label[tooltipItem.index] + ' ' + formatNumber(e.data[tooltipItem.index])
                })

                message = message.filter((e) => {
                  return !!e
                })

                return message
              } else {
                var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                if (valor === null) {
                  return null
                }

                if (valor || valor === 0) {
                  // Number format function
                  return formatNumber(valor)
                }

                return false
              }
            }
          },
          backgroundColor: '#666666',
          titleFontSize: 10,
          titleSpacing: 10,
          titleAlign: 'center',
          titleMarginBottom: 10,
          bodySpacing: 4,
          titleFontColor: '#ffffff',

          bodyFontColor: '#ffffff',
          bodyFontSize: 14,
          cornerRadius: 10,
          yPadding: 10,
          xPadding: 10,
          displayColors: false
        },
        legend: {
          display: false,
          position: 'top',
          labels: {
            boxWidth: 10,
            fontColor: '#666666'
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          border: {
            display: true
          },
          xAxes: [{
            border: {
              display: true
            },
            gridLines: {
              display: false,
              color: '#ffffff'
            },
            ticks: {
              beginAtZero: true,
              fontColor: '#666666'
            }
          }],
          yAxes: [{
            position: 'right',
            gridLines: {
              color: '#666666',
              lineWidth: 1,
              zeroLineWidth: 1
            },
            ticks: {
              beginAtZero: false,
              stepSize: 1000,
              fontColor: '#666666',
              callback: function (value, index, values) {
                if (parseInt(value) >= 500) {
                  return 'USD' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, decimalSeparator: ',' })
                } else {
                  return 'USD' + value
                }
              }
            }
          }]
        }
      },
      chartOptions2: {
        spanGaps: true,
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              if (data.datasets.length > 1) {
                let label = data.labels[tooltipItem[0].index]

                if (!label) {
                  return null
                }

                return null
              } else {
                let label = data.labels[tooltipItem[0].index]

                if (!label) {
                  return null
                }

                return null
              }
            },
            label: function (tooltipItem, data) {
              let formatNumber = function (number) {
                let decimals = 2
                let decPoint = ','
                let thousandsSep = '.'

                number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
                var n = !isFinite(+number) ? 0 : +number
                var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
                var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
                var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
                var s = ''

                var toFixedFix = function (n, prec) {
                  var k = Math.pow(10, prec)
                  return '' + (Math.round(n * k) / k)
                    .toFixed(prec)
                }

                // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
                s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
                if (s[0].length > 3) {
                  s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
                }
                if ((s[1] || '').length < prec) {
                  s[1] = s[1] || ''
                  s[1] += new Array(prec - s[1].length + 1).join('0')
                }
                // End Number format function
                return s.join(dec)
              }

              if (data.datasets.length > 1) {
                let message = ''

                message = data.datasets.map((e) => {
                  if (!e.data[tooltipItem.index]) {
                    return null
                  }
                  return e.label[tooltipItem.index] + ' ' + formatNumber(e.data[tooltipItem.index])
                })

                message = message.filter((e) => {
                  return !!e
                })

                return message
              } else {
                var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                if (valor === null) {
                  return null
                }

                if (valor || valor === 0) {
                  // Number format function
                  return formatNumber(valor)
                }

                return false
              }
            }
          },
          backgroundColor: '#666666',
          titleFontSize: 10,
          titleSpacing: 10,
          titleAlign: 'center',
          titleMarginBottom: 10,
          bodySpacing: 4,
          titleFontColor: '#ffffff',

          bodyFontColor: '#ffffff',
          bodyFontSize: 14,
          cornerRadius: 10,
          yPadding: 10,
          xPadding: 10,
          displayColors: false
        },
        legend: {
          display: false,
          position: 'right',
          align: 'start',
          labels: {
            boxWidth: 10,
            fontColor: '#666666'
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          border: {
            display: true
          },
          xAxes: [{
            border: {
              display: true
            },
            gridLines: {
              display: false,
              color: '#ffffff'
            },
            ticks: {
              beginAtZero: true,
              fontColor: '#666666'
            }
          }],
          yAxes: [{
            position: 'right',
            gridLines: {
              color: '#666666',
              lineWidth: 1,
              zeroLineWidth: 1
            },
            ticks: {
              beginAtZero: false,
              stepSize: 1000,
              fontColor: '#666666',
              callback: function (value, index, values) {
                if (parseInt(value) >= 500) {
                  return 'USD' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, decimalSeparator: ',' })
                } else {
                  return 'USD' + value
                }
              }
            }
          }]
        }
      },
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      dataHoje: string,
      tl: Object,
      idorigem: '005',
      isActive: true,
      iddestino: true,
      graf: 1,
      tradelane: true,
      oldAtr: 1,
      color: '#000',
      tradelane1: Object,
      tradelane2: Object,
      tradelane4: Object,
      tradelane3: Object,
      AllTradelane: Object,
      lastElementIndex: String,
      paused: false,
      isLoading: true,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      endpoint: process.env.VUE_APP_APIENDPOINT + 'v3/indice',
      multiSort: true,
      value: true,
      activeFilterList: {
        begin_date: null,
        end_date: null
      },
      filter: {},
      fieldsTable: [
        {
          name: 'ano',
          title: this.$i18n.t('Ano'),
          sortField: 'ano',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'mes',
          title: this.$i18n.t('Mes'),
          sortField: 'mes',
          formatter: (value) => this.$util.getMonthWritten(value - 1, 'pt-BR', true)
        },
        {
          name: 'tradelane',
          title: this.$i18n.t('TradeLane'),
          sortField: 'tradelane'
        },
        {
          name: 'variacaoValor',
          title: this.$i18n.t('% Variação Valor'),
          sortField: 'variacaoValor',
          formatter: (value) => this.$util.numberFormat(value * 100.0, 2, ',', '.')
        },
        {
          name: 'dolar',
          title: this.$i18n.t('Dolar'),
          sortField: 'dolar',
          formatter: (value) => this.$util.numberFormat(value, 2, ',', '.')
          // formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'variacaoFrete',
          title: this.$i18n.t('Variação Frete'),
          sortField: 'variacaoFrete',
          formatter: (value) => this.$util.numberFormat(value, 2, ',', '.')
        }
      ],
      sortOrder: [
        { field: 'ano', direction: 'asc' },
        { field: 'mes', direction: 'asc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    AppAccordionIndex,
    LineChart,
    LineChartGlobal,
    MarqueeText,
    Loading,
    Sparkline,
    Modal
  },
  // Apply filters to local filter
  beforeMount () {
    this.showTradeLane()
  },
  mounted () {
    const initUserTheme = this.selecionaPref()
    this.setTheme(initUserTheme)
  },
  created () {
    window.location.return = false
    window.addEventListener('popstate', this.reloadOnBack())
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.reloadOnBack())
  },
  methods: {
    reloadOnBack (event) {
      if (window.location.return) {
        window.location.reload(true)
      }
      window.location.return = true
    },
    showModal () {
      document.body.style.overflow = 'hidden'
      this.isModalVisible = true
    },
    closeModal () {
      document.body.style.overflow = 'auto'
      this.isModalVisible = false
    },
    chartData (chartData) {
      let data = []

      data = chartData.map(x => {
        return x.variacaoFrete
      })

      return data
    },
    chartLabels (chartData) {
      let _this = this
      let data = []

      data = chartData.map(x => {
        return _this.$util.getMonthWritten(x.mes - 1, 'pt-BR', true) + '-' + x.ano
      })

      return data
    },
    filterNumber (obj) {
      if ('id' in obj && typeof (obj.id) === 'number' && !isNaN(obj.id)) {
        return true
      } else {
        return false
      }
    },
    changeColor () {
      const activeTheme = localStorage.getItem('user-theme')
      if (activeTheme === 'light-theme') {
        this.color = '#000'
      } else {
        this.color = '#fff'
      }
      document.getElementsByClassName('month2')[0].style.color = this.color
      document.getElementsByClassName('month')[0].click()
      // setInterval(document.getElementsByClassName('mont')[0].click(), 2000000000)
    },
    setTheme (theme) {
      localStorage.setItem('user-theme', theme)
      this.userTheme = theme
      document.documentElement.className = theme
    },
    trocaTema () {
      const activeTheme = localStorage.getItem('user-theme')
      if (activeTheme === 'light-theme') {
        this.setTheme('dark-theme')
      } else {
        this.setTheme('light-theme')
      }
    },
    selecionaPref () {
      const hasDarkPreference = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches
      if (hasDarkPreference) {
        return 'dark-theme'
      } else {
        return 'light-theme'
      }
    },
    getTheme () {
      return localStorage.getItem('user-theme')
    },
    alteraClasse (atr, old, sparkline) {
      if (atr === old) {
        return
      }
      this.lastElementIndex = sparkline
      document.getElementsByClassName(atr)[0].style.border = '2px solid #E0DCD2'
      this.oldAtr = atr
      document.getElementsByClassName(old)[0].removeAttribute('style')
    },
    alteraClasse2 (atr, old, sparkline) {
      if (atr === old) {
        return
      }
      this.lastElementIndex = sparkline
      document.getElementsByClassName(atr)[1].style.border = '2px solid #E0DCD2'
      this.oldAtr = atr
      document.getElementsByClassName(old)[1].removeAttribute('style')
    },
    month1 () {
      let month3 = document.getElementsByClassName('month3')[0]
      let month2 = document.getElementsByClassName('month2')[0]
      let hr = document.getElementsByClassName('hr')[0]
      let hr2 = document.getElementsByClassName('hr2')[0]
      month3.style.color = this.color
      month2.removeAttribute('style')
      hr.style.display = 'flex'
      hr2.style.display = 'none'
      document.getElementsByClassName('mont')[0].click()
    },
    month2 () {
      let month3 = document.getElementsByClassName('month3')[0]
      let month2 = document.getElementsByClassName('month2')[0]
      let month = document.getElementsByClassName('month')[0]
      let hr = document.getElementsByClassName('hr')[0]
      let hr2 = document.getElementsByClassName('hr2')[0]
      month2.style.color = this.color
      month.classList.remove('month1')
      month3.removeAttribute('style')
      hr2.style.display = 'flex'
      hr.style.display = 'none'
    },
    mont1 () {
      let month3 = document.getElementsByClassName('mont3')[0]
      let month2 = document.getElementsByClassName('mont2')[0]
      let hr = document.getElementsByClassName('h2r')[0]
      let hr2 = document.getElementsByClassName('h2r2')[0]
      month3.style.color = this.color
      month2.removeAttribute('style')
      hr.style.display = 'flex'
      hr2.style.display = 'none'
    },
    mont2 () {
      let month3 = document.getElementsByClassName('mont3')[0]
      let month2 = document.getElementsByClassName('mont2')[0]
      let month = document.getElementsByClassName('mont')[0]
      let hr = document.getElementsByClassName('h2r')[0]
      let hr2 = document.getElementsByClassName('h2r2')[0]
      month2.style.color = this.color
      month.classList.remove('mont1')
      month3.removeAttribute('style')
      hr2.style.display = 'flex'
      hr.style.display = 'none'
    },
    fillData () {
      this.tradelane1.data = {
        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [
          {
            backgroundColor: '#f87979',
            data: [70, 20, 12, 39, 100, 40, 95, 80, 80, 20, 12, 101]
          }
        ]
      }
    },
    IndexService () {
      var key = Object.keys(this.AllTradelane.summary)[0]
      this.onLoading()
      IndexService.getMostRecent(this.idorigem, this.iddestino).then(res => {
        this.tradelane1 = res.data
        if (this.onLoad) {
          document.getElementsByClassName(this.AllTradelane.summary[key].codigo)[0].click()
          this.onLoad = false
        }
        this.value = this.tradelane1.data.slice(0, 12)
        document.getElementsByClassName('mont')[0].click()
        this.onLoaded()
      })
    },
    showTradeLane () {
      var data = new Date()
      const meses = {
        0: 'Jan',
        1: 'Fev',
        2: 'Mar',
        3: 'Abr',
        4: 'Mai',
        5: 'Jun',
        6: 'Jul',
        7: 'Ago',
        8: 'Set',
        9: 'Out',
        10: 'Nov',
        11: 'Dez'
      }
      const mes = meses[data.getMonth()]
      const ano = data.getFullYear().toString()
      this.dataHoje = `${mes} ${ano}`
      IndexService.getIndex().then(res => {
        this.AllTradelane = res.data
        var lastElement
        var last
        for (last in this.AllTradelane.summary) {
          lastElement = last
        }
        this.idorigem = this.AllTradelane.summary[lastElement].idorigem_trade_lane
        this.iddestino = this.AllTradelane.summary[lastElement].iddestino_trade_lane
        this.lastElementIndex = this.AllTradelane.summary[lastElement].sparkline
      })
      IndexService.getMostRecentIndex().then(res => {
        this.tradelane1 = res.data
        this.tradelane3 = this.tradelane1
      })
      IndexService.getMostRecentIndex2().then(res => {
        this.tradelane2 = res.data
      })
    },
    alteradados () {
      this.dados = this.dados2
    },
    async delete (item) {
      let _this = this
      _this.isLoading = true
      await ImportacaoDeParaService.delete(item).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).finally(() => {
        _this.isLoading = false
      })
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsTable
    },

    process () {
      return this.$route.params.process || null
    },
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  ready () {
  },
  watch: {
    idorigem: function () {
      this.IndexService()
    },
    process: function () {
      this.filter.internal_code = this.$route.params.process
      this.filters.filProcesso = this.$route.params.process
      this.$refs.internal_code.value = this.$route.params.process

      this.applyFilter()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>
<style>
  @media only screen and (max-width: 768px) {
    .col-3mobile{
      display: flex !important;
      justify-content: center;
      padding-top: 1rem !important;
      padding-left: 10px !important;

    }
    .col-3-mobile{
      display: none;
    }
    .header-rfx{
      justify-content: flex-start !important;
      padding-top: 10px !important;
      width: 100% !important;
    }
    .header-rfx a {
      padding: 0 10px !important;
    }
    .header-rfx h3 {
      padding-right: 4px !important;
      font-size: 20px !important;
    }
    .header-royal{
      padding-top: 4rem !important;
      flex-direction: column !important;
    }
    .plus{
      margin-right: 0rem !important
    }
    .card-topo2{
      max-width: 325px !important;
    }
    .perguntas{
      margin-left: 0rem !important;
    }
    .switch{
      margin-right: -95% !important;
    }
    .links {
    width: 20rem !important;
    }
    .sparkline-mobile{
      visibility: hidden;
    }
    .modal{
      left: 5%;
      width: 90%;
    }

    .box-rfx{
      width: 109% !important;
      margin-left: -10px !important
    }
    .box-rfx-hd{
      min-width: 100% !important;
      margin-left: -10px !important;
    }
    .volat{
      flex-direction: column !important;
    }
    .volat .col-xs-12{
      min-width: 340px !important;
      padding-top: 2rem;
    }
    .sub-box{
      padding-top: 1rem !important;
    }
    .rowmobile{
      display: flex;
      flex-direction: column;
    }
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
    .sparkline {
      display: flex !important;
    }
  }
  @media only screen and (max-width: 1412px) and (min-width: 1350px) {
    /* .sparkline {
      display: none;
    } */
    .box-rfx-hd{
      width: 450px;
      margin-left: 40px !important;
    }
    .box-rfx-s{
      width: 330px !important;
    }
  }
  @media only screen and (max-width: 1350px) and (min-width: 1188px) {
    .sparkline {
      display: none;
    }
    .box-rfx-hd{
      margin-right: -10px !important;
      margin-left: 90px !important;
    }
    .box-rfx-s{
      width: 290px !important;
    }
  }
  @media only screen and (max-width: 1188px) and (min-width: 1000px) {
    .box-rfx-hdd{
      min-width: 402px !important;
    }
    .box-rfx-s{
      width: 233px !important;
    }
    .sparkline {
      display: none;
    }
    .card22{
      margin-left: -2rem;
    }
  }
  @media only screen and (min-width: 1700px) {
    .lane {
      width: 230px !important;
    }
  }
  *,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background-color-primary: #f3f3f3;
  --background-color-secondary: #FFFFFF;
  --border: #E0E0E0;
  --accent-color: #cacaca;
  --text-primary-color: #3D3D3D;
  --text-secondary-color: #666666;
  --fill: #877953;
  --stroke: #877953;
}

:root.dark-theme {
  --background-color-primary: #161817;
  --background-color-secondary: #333333;
  --border: #333333;
  --accent-color: #3f3f3f;
  --text-primary-color: #FFFFFF;
  --text-secondary-color: #E0E0E0;
  --fill: #FFFFFF;
  --stroke: #FFFFFF;
}
.fill{
  fill: var(--fill) !important;
  stroke: var(--stroke) !important;
}
.col-3mobile{
  display: none;
}
.paragraph{
  color: var(--text-secondary-color) !important;
}
.sub-title{
  font-family: 'Pluto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.02em;

  color: var(--text-secondary-color);
}
.switch{
  position: absolute;
  margin-right: -105%;
}
.plus{
  width:24px;
  height: 24px;
  background: #333333;
  border-radius: 8px;
  /* margin-right: 9rem; */
}
/* .card {
  background-color: var(--background-color-secondary);
} */
.switch-checkbox {
  display: none;
}
.switch-label {
  width: 40px;
  height: 40px;
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: calc(var(--element-size) * 0.1);
  font-size: calc(var(--element-size) * 0.3);
  align-items: center;
  background: var(--background-color-secondary);
  cursor: pointer;
  display: flex;
  position: relative;
  transition: background 2s ease;
  justify-content: space-between;
  z-index:100;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.month2, .month3, .mont2, .mont3, .subtitle{
  color: var(--text-primary-color);
}

.switch-toggle-checked {
  transform: translateX(calc(var(--element-size) * 0.6)) !important;
}
.volat{
  display: flex;
  justify-content: space-between;
}
.hm div {display:none;position:relative}
.hm:hover div {display:flex;position:absolute;top:0;margin-left:40px}
.hm {
  cursor: help;
}

.hm div {
  margin-top: 1rem;
  margin-left: 5rem !important;
  color:#fff;
  background:#999999cc;;
  border-radius:5px;
  padding:5px;
  position:absolute
}
.hm2{
  margin-top: 4rem !important;
}
.hr, .hr2{
  height: 4px;
  background: #84754E;
  border-radius: 10px;
  margin-top: -1rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 3px solid #84754E;
  width: 68%;
}
.h2r, .h2r2{
  height: 4px;
  background: #84754E;
  border-radius: 10px;
  margin-top: -1rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 3px solid #84754E;
}
.h2r3{
  height: 4px;
  background: #afafaf;
  border-radius: 10px;
  margin-top: -1rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 3px solid #afafaf;
}
.mailto{
  color: rgb(132, 117, 78) !important;
}
.hr2{
  width: 86%;
  display: none
}
.card-body {
  padding: 0.5rem 0.8rem;
}
 .mont1, .month1{
  color: var(--text-primary-color) !important
 }
.mais {
  position: relative;
  display: block;
  background: rgba(0, 0, 0, 0);
  width: 20px;
  height: 1px;
  top: 10px;
  transition: 0.5s ease-in-out;
}

.mais:before,
.mais:after {
  background: rgb(255, 255, 255);
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.5s ease-in-out;
}

.mais:before {
  transform: rotate(90deg);
}

input {
  display: none;
}

input:checked ~ label .mais:before {
  transform: rotate(405deg);
  top: 0;
}
  input:checked ~ label .mais:after {
    transform: rotate(135deg);
    bottom: 0;
  }
  .title{
    border-top: 0px;
  }
  .month{
    cursor: pointer;
    width: 107px;
    height: 44px;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #84754E;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .mont{
    cursor: pointer;
    height: 34px;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #84754E;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .subtitle{
    height: 34px;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: var(--text-primary-color);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .hr-footer{
    background-color: #fff;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .header-h3{
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: center;
  }
  .header-h{
    color: #fff;
    font-size: 17px;
    margin-top: 3%
  }
  .rowmobile{
    padding-top: 2rem;
  }
  .header1{
    border-radius: 0px 20px 0px 0;
    background-color: #333;
    display: flex;
    justify-content: center;
    padding-top: 4px;
    font-size: 17px;
    width: 9rem;
    height: 2.5rem;
    max-height: 115%;
  }
  .header2{
    z-index: 9999999;
    border-radius: 0px 0px 20px 0;
    border-top: 1px solid #e8e8e8;
    background-color: #3333334a;
    display: flex;
    justify-content: center;
    padding-top: 4px;
    font-size: 17px;
    width: 9rem;
    height: 2.5rem;
    max-height: 115%;
    padding-bottom: 2rem;
  }
  .template{
    background-color: #ccccccfc;
    height: 100%;
    border-radius: 0.6rem 0.6rem 0 0;
    padding-top: 5rem;
    width: 100%;
    height: 100%;
  }
  .marquee-text-wrap{
    margin-left: -2rem;
    /* margin-right: -2rem; */
  }
  .content-area{
    padding-left: 0  !important;;
  }
  /* .page-sidebar, .whatsapp-fixed{
    visibility: hidden;
  } */
  .button{
    font-weight: bold;
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 15px;
    line-height: 0.93;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: padding-box#877953;
    padding: 15px 10px 20px;
    border-width: 0px;
    border-radius: 0px 0px 3px 3px;
    outline: none;
  }
  .button:hover {
    background-color: #000000;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
  }
  .card-body-text{
    height: 29.5rem;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: space-around;
    text-align: center;
    padding: 1rem;

  }
  .roow-bottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
  }
  .card-body-text h2{
    margin-bottom: -7rem;
    font-size: 50px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 1;
  }
  .input{
    border-radius: 3px;
    max-width: 9rem;
    text-align: center;
    font-size: 12px;
    margin-left: 5px;
    color: rgb(255, 255, 255);
    background-color: rgba(31, 38, 69, 0.466);
    border: none;
    padding-left: 5px;
  }
  .ganho{
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    color: var(--text-primary-color);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .card-topo{
    /* box-shadow: 0 1px 15px 1px #21252924 !important; */
    display: flex;
    align-items: center;
    min-height: 5.5rem;
    flex-direction: row;
  }
  .card-topo:hover {
     background-color: #2125293d;
  }
  .card-graphic{
    box-shadow: none;
    border: 1px solid var(--background-color-secondary);
  }
  .card2{
    box-shadow: none;
    background-color: var(--background-color-secondary);
  }
  .topo{
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between
  }
  .lane{
    width: 100%;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: -0.154px;

    color: var(--text-primary-color);
  }
  .primary{
    color: var(--text-primary-color) !important;
  }
  .secondary{
    color: var(--text-primary-color) !important;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
  }
  .title1{
    width: 61px;
    height: 34px;
    font-weight: 700;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.154px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: var(--text-primary-color);
  }
  .letra{
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .badge-danger {
    margin-top: 0px;
    color: #fff;
    background-color: #dc3545;
    border-radius: 16px;
  }
  .badge-success {
    margin-top: 0px;
    border-radius: 16px;
    color: #fff;
    background-color: #28a745;
  }
  .swal2-popup {
    width: inherit !important;
  }
  .hello {
    width: 106%;
    height: 500px
  }
  .fixed-top {
    display: none !important;
  }
  .titleRFX {
    margin-bottom: 25px;
    width: 887px;
    max-width: 100%;
  }
  .title-header {
    font-size: 34px;
    font-weight: bold;
    line-height: 41px;
    color: var(--text-primary-color);
    padding-bottom: 15px;
  }
  .chartMobile{
    padding-bottom: 3rem;
    padding-top: 3rem;
    background: var(--background-color-primary);
    z-index: 1054 !important
  }
  .sub-title2 {
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: var(--text-secondary-color);
  }
  .subBoxtitle {
    color: 7D807F;
    line-height: 26px;
  }
  .jhSDsE {
    color:#80754E !important;
    outline: none;
  }
  .box-porc{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 4px 8px;
    gap: 10px;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.154px;
    color: #fff;
    width: 61px;
    height: 24px;
    /* background: #EB5757; */
    border-radius: 16px;
    /* margin-top: 0.7rem; */
  }
  .box-rfx {
    min-width: 120px;
    width: auto;
    height: 92%;
    position: relative;
    box-sizing: border-box;
    text-align: inherit;
    background-color: var(--background-color-secondary);
    padding: 22px 22px;
    margin: 0px;
    border-radius: 20px;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .between-xs {
    justify-content: space-between;
  }

  .bottom-xs {
      align-items: flex-end;
  }
  .roow {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;

  }
  .box-rfx2 {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 0px;
    align-items: center;
  }
  .hPtSG {
    font-size: 14px;
    color: rgb(174, 241, 203);
    display: inline-block;
    padding-left: 20px;
    position: relative;
  }
  .text-rfx {
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.154px;
    width: 85%;
    color: var(--text-primary-color);
  }
  .text-box {
    font-size: 29px;
    font-weight: bold;
    color: var(--text-primary-color);
    margin-top: 1rem !important;
  }
  .fQLwXH {
    font-size: 14px;
    color: rgb(91, 177, 231);
    display: inline-block;
    padding-left: 20px;
    position: relative;
  }
  .card-topo2{
    z-index: 9;
    cursor: pointer;
    min-width: 17rem;
    min-height: 112px;
    background-color: var(--background-color-secondary) !important;
  }
  .otBsS {
    font-size: 29px;
    font-weight: bold;
    color: white;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 72px;
    margin-left: -2.2rem;
    background-color: #000000;
    margin-right: -2.2rem;
  }
  .logoEroyal {
    padding-left: .5vw;
    width: 217px;
    height: 83px;
    max-width: 100%;
  }
  .page-footer{display: none !important;}
  .links{
    width: 25rem ;
    /* margin: -20px 10px 20px 0; */
    display: flex;
    justify-content: space-around;
  }
  .vld-overlay.is-full-page {
    z-index: 1060;
    position: fixed;
  }
  .content {
    background: #ffffff00;
  }
  .voltar{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 20px;
    margin-bottom: -13px;
    height: 33px;
    background: #E0E0E0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .voltar span{
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.154px;
    color: #FFFFFF;
    text-transform: uppercase;
  }
</style>
